
import { Component, Prop, Vue } from "vue-property-decorator";
import DialogIdentify from "@/components/request/DialogIdentify.vue";
import FormCard from "@/components/FormCard.vue";
import RegisterForm from "@/components/forms/RegisterForm.vue";
import GuestForm from "@/components/forms/GuestForm.vue";

@Component({
  components: {
    DialogIdentify,
    FormCard,
    RegisterForm,
    GuestForm
  }
})
export default class IdentifyUser extends Vue {
  @Prop({ default: false }) readonly date_request!: boolean;
  @Prop({ default: true }) readonly stepless!: boolean;

  dialog = true;
  auth_method = "";
  private identifyUser(method: string) {
    if (method === "log") {
      this.auth_method = "log";
    } else if (method === "register") {
      this.auth_method = "register";
    } else if (method === "guest") {
      this.auth_method = "guest";
    }
  }
  private mounted() {
    window.scroll(0, 0);
  }

  private showError($event: string) {
    this.$q.notify({
      message: ("<div style='font-size:20px'>" +
        this.$t("error." + $event) +
        "</div>") as string,
      color: "accent",
      html: true,
      position: "top",
      timeout: 5000
    });
    this.dialog = true;
  }

  private loggedFromRegister() {
    this.auth_method = "log";
  }
  private get showGuestForm() {
    if (this.auth_method === "guest" || this.auth_method === "log") {
      return true;
    }
    return false;
  }
}
