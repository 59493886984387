
import { Vue, PropSync, Component, Prop } from "vue-property-decorator";
import LoginForm from "@/components/forms/LoginForm.vue";
import FormCard from "@/components/FormCard.vue";
import StepTracker from "@/components/request/StepTracker.vue";

@Component({
  components: {
    FormCard,
    LoginForm,
    StepTracker
  }
})
export default class DialogIdentify extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(Boolean) stepless!: boolean;

  private mounted() {
    if (this.$store.state.logged === true) {
      this.userIdentification("log");
    }
  }
  private userIdentification(method: string) {
    this.syncedDialog = false;
    this.$emit("identify", method);
  }
}
