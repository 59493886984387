
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import comunas from "@/helpers/comunas.json";
import countries from "@/helpers/countries.json";

interface guestData {
  name: string;
  father_last_name: string;
  mother_last_name: string;
  nationality: any;
  document_type: string;
  document_id: string;
  motive: any;
  district: any;
  street: string;
  street_number: string;
  hint: string;
  email: string;
  phone: string;
  facebook: any | null;
  instagram: any | null;
  twitter: any | null;
}

@Component
export default class GuestForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly date_request!: boolean;

  options_document: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];

  options_motive: object[] = [
    { label: "Solicitud", value: "0" },
    { label: "Reclamo", value: "1" },
    { label: "Sugerencia", value: "2" }
  ];

  options_district: any = comunas;
  options_nationality: any = countries;
  options_street: any = [];
  street_loading = false;
  street_search_empty = false;
  chilean = true;

  protected formData: guestData = {
    name: "",
    father_last_name: "",
    mother_last_name: "",
    nationality: {
      value: 47,
      code: "CL",
      country_en: "Chile",
      country_es: "Chile",
      label: "Chilena"
    },
    motive: "",
    document_type: "1",
    document_id: "",
    district: "",
    street: "",
    street_number: "",
    hint: "",
    email: "",
    phone: "",
    facebook: null,
    instagram: null,
    twitter: null
  };

  private mounted() {
    this.loadStoredData();
    this.formatRut();
    this.highlightMotive();
  }

  private highlightMotive() {
    window.scrollTo(0, 0);
    (this.$refs.guestForm as any).validate();
  }
  private clearStreet(): void {
    if (this.formData.district.label == this.comuna_cliente) {
      this.formData.street = "";
    }
  }
  private loadStoredData(): void {
    if (this.isUserLogged) {
      this.formData.name = this.$store.state.user.name;
      this.formData.father_last_name = this.$store.state.user.father_last_name;
      this.formData.mother_last_name = this.$store.state.user.mother_last_name;
      this.formData.nationality = countries.find(
        country => country.value === this.$store.state.user.country
      );
      this.formData.document_type = this.$store.state.user.document_type.toString();
      this.formData.document_id = this.$store.state.user.rut;
      this.formData.district = comunas.find(
        district => district.label === this.$store.state.user.adress.district
      );
      this.formData.street = this.$store.state.user.adress.street;
      this.formData.street_number = this.$store.state.user.adress.number;
      this.formData.hint = this.$store.state.user.adress.hint;
      this.formData.email = this.$store.state.user.email;
      this.formData.phone = this.$store.state.user.phone;
      this.formData.facebook = this.$store.state.user.facebook;
      this.formData.instagram = this.$store.state.user.instagram;
      this.formData.twitter = this.$store.state.user.twitter;
      if (this.$store.state.ticket.motive !== "") {
        this.formData.motive = this.options_motive.find(
          motive => (motive as any).value == this.$store.state.ticket.motive
        );
      }
    } else if (this.$store.state.ticket.guest) {
      this.formData.name = this.$store.state.ticket.guest_name;
      this.formData.father_last_name = this.$store.state.ticket.guest_father_name;
      this.formData.mother_last_name = this.$store.state.ticket.guest_mother_name;
      this.formData.nationality = countries.find(
        country => country.value === this.$store.state.ticket.guest_country
      );
      this.formData.document_type = this.$store.state.ticket.guest_document_type.toString();
      this.formData.document_id = this.$store.state.ticket.guest_id;
      this.formData.district = comunas.find(
        district =>
          district.label === this.$store.state.ticket.guest_adress_district
      );
      this.formData.street = this.$store.state.ticket.guest_adress_street;
      this.formData.street_number = this.$store.state.ticket.guest_adress_number;
      this.formData.hint = this.$store.state.ticket.guest_adress_hint;
      this.formData.email = this.$store.state.ticket.guest_email;
      this.formData.phone = this.$store.state.ticket.guest_phone;
      if (this.$store.state.ticket.motive !== "") {
        this.formData.motive = this.options_motive.find(
          motive => (motive as any).value == this.$store.state.ticket.motive
        );
      }
    }
  }

  private identifyAttemp(): void {
    let ticket_data: any = {};
    ticket_data.motive = this.formData.motive.value;
    ticket_data.step = 3;
    if (!this.$store.state.logged) {
      this.$axios
        .post("personas/identificar", {
          nombre: this.formData.name,
          apellido_paterno: this.formData.father_last_name,
          apellido_materno: this.formData.mother_last_name,
          email: this.formData.email,
          telefono: this.formData.phone,
          tipo_documento: Number(this.formData.document_type),
          rut: this.formData.document_id,
          id_pais: this.formData.nationality.value,
          //borrar una vez que se arregle el endpoint
          facebook: this.formData.facebook,
          instagram: this.formData.instagram,
          twitter: this.formData.twitter,
          direccion: {
            id_comuna: this.formData.district.value,
            calle: this.formData.street,
            numero: this.formData.street_number,
            aclaratoria: this.formData.hint,
            //borrar una vez se arregle el endpoint
            tipo: 0
          }
        })
        .then(response => {
          let payload = {
            ticket: ticket_data,
            guest: response.data
          };
          this.$store.dispatch("submitRequestStepTwo", payload);
        })
        .catch(error => {
          this.$emit("error", error.response.data.error.message);
        });
    } else {
      let payload = {
        ticket: ticket_data
      };
      this.$store.dispatch("submitRequestStepTwo", payload);
    }
  }

  private get isUserLogged(): boolean {
    return this.$store.state.logged;
  }

  private checkIfChilean() {
    if (this.formData.nationality.value === 47) {
      this.chilean = true;
      this.formData.document_type = "1";
    } else {
      this.chilean = false;
    }
  }

  private async filterStreet(val: string, update: any, abort: any) {
    this.street_loading = true;
    if (val === "") {
      update(() => {
        this.options_street = [];
      });
      this.street_loading = false;
      this.street_search_empty = true;
      return;
    }
    await this.loadStreets(val);

    setTimeout(() => {
      update(() => {});
    }, 200);

    return;
  }

  private async loadStreets(val: string) {
    const value = val.toLowerCase();
    let streets = await this.apiStreets(val);
    if (streets.err) {
      this.options_street = [];
      return;
    }
    this.street_loading = false;
    this.street_search_empty = false;
    this.options_street = streets;
  }

  private filterDistrict(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_district = comunas;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_district = (comunas as any).filter((comuna: any) => {
        return comuna.label.toLowerCase().indexOf(value) > -1;
      });
      if (this.formData.district) {
        this.options_district.unshift(this.formData.district);
      }
    });
  }

  private abortFilterFn() {
    this.options_district = comunas;
  }

  private filterNationality(val: string, update: any) {
    if (val === "") {
      update(() => {
        this.options_nationality = countries;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_nationality = (countries as any).filter((country: any) => {
        let filtered = country.label.toLowerCase().indexOf(value) > -1;
        return filtered;
      });
      if (this.formData.nationality) {
        this.options_nationality.unshift(this.formData.nationality);
      }
    });
  }
}
