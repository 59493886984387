
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface loginData {
  document_type: string;
  document_id: string;
  password: string;
}

@Component
export default class LoginForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: loginData = {
    document_type: "1",
    document_id: "",
    password: ""
  };

  private credentials_found = true;

  private mounted() {
    this.formatRut();
    if (this.$route.query.timeout == "true") {
      this.$q.notify({
        message: this.$t("notification.timeout") as string,
        color: "accent",
        position: "top",
        timeout: 5000
      });
    }
  }
  private loginAttemp(): boolean {
    if (this.isFormValid) {
      this.$axios
        .post("personas/common/login", {
          tipo_documento: Number(this.formData.document_type),
          rut: this.formData.document_id,
          password: this.formData.password
        })
        .then(response => {
          this.$store.dispatch("login", response.data);
          this.$q.notify({
            message: this.$t("notification.now_logged") as string,
            color: "accent",
            position: "top",
            timeout: 7500
          });
          this.redirectIntended();
        })
        .catch(error => {
          this.setCredentialsNotFound();
        });
      return true;
    } else {
      return false;
    }
  }
  private redirectIntended(): void {
    if (this.quick_login) {
      this.$emit("logged");
      return;
    }
    if (this.$route.query.redirect === "login") {
      return;
    }
    const intended: string =
      typeof this.$route.query.redirect === "string"
        ? this.$route.query.redirect
        : ("home" as const);
    this.$router.push({ name: intended });
  }

  private get isFormValid(): boolean {
    if (this.formData.document_id === "" || this.formData.password === "") {
      return false;
    } else {
      return true;
    }
  }

  private setCredentialsNotFound() {
    this.credentials_found = false;
    (this.$refs.form as any).validate();
  }

  private credentialsFound() {
    if (this.credentials_found) {
      return true;
    } else {
      return this.$t("validation.invalid_credentials");
    }
  }
}
